import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

const stripePromise = loadStripe('pk_test_51NIWXEFC1gRl8evvNSkKRtEyaLPaR77JNT12rI1C8rBgVQOjpWHTChfDT0iGQAUZFuZ7nE3rTbvr1HVCU7qK93WI00VhGYeirI');
const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [customerId, setCustomerId] = useState(null);

    const createCustomer = async () => {
        const response = await axios.post('https://techcogads.com/api/Payments/create-customer', {
            name: "Customer Name", // Replace with actual customer name
            email: "customer@example.com" // Replace with actual customer email
        });

        setCustomerId(response.data.CustomerId);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error: methodError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (methodError) {
            setError(methodError.message);
            return;
        }

        await createCustomer();

        const amountInPaise = 5000; // 5000 paise = 50 INR
        const { data } = await axios.post('https://techcogads.com/api/payments/create-payment-intent', {
            amount: amountInPaise,
            customerId: customerId,
            paymentMethodId: paymentMethod.id,
        });

        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(data.clientSecret);

        if (confirmError) {
            setError(confirmError.message);
        } else if (paymentIntent.status === 'succeeded') {
            setSuccess(true);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <button type="submit" disabled={!stripe}>Pay</button>
            {error && <div>{error}</div>}
            {success && <div>Payment Successful!</div>}
        </form>
    );
};

const Payment = () => (
    <Elements stripe={stripePromise}>
        <CheckoutForm />
    </Elements>
);

export default Payment;

