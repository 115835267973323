import React, { useEffect, useState } from 'react'

const Backbutton = () => {

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);


    return (
        <>
            {isVisible && (
                <div className="fixed bottom-0 right-0 p-4 scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                    <button onClick={scrollToTop} className="bg-gray-800 text-white rounded-full w-8 h-8 flex items-center justify-center" onclick="backToTop()">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18"></path>
                        </svg>
                    </button>
                </div>
            )}
        </>
    )
}

export default Backbutton