import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'Page A',
        Sales: 4000,
        Ads: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        Sales: 3000,
        Ads: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        Sales: 2000,
        Ads: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        Sales: 2780,
        Ads: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        Sales: 1890,
        Ads: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        Sales: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        Sales: 3490,
        Ads: 4300,
        amt: 2100,
    },
];

export default class Example extends PureComponent {
    static demoUrl = 'https://codesandbox.io/p/sandbox/line-chart-double-y-axes-2stmj2';

    render() {
        return (
            <ResponsiveContainer width="100%" height="90%" className={'font-arapey'}>
                <LineChart
                    width={500}
                    height={500}
                    data={data}
                    margin={{
                        top: 50,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="Sales" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line yAxisId="right" type="monotone" dataKey="Ads" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        );
    }
}
