import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/logo.png';

const ResetPassword = ({ setProgress }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({}); // State variable to manage errors
    const navigate = useNavigate();

    // For Loader
    useEffect(() => {
        setProgress(40);

        setTimeout(() => {
            setProgress(80);
        }, 1000);
        setTimeout(() => {
            setProgress(100);
        }, 2000);

    }, [])


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrors(prevErrors => ({ ...prevErrors, email: 'Invalid email address.' }));
            return false;
        } else {
            setErrors(prevErrors => ({ ...prevErrors, email: '' }));
            return true;
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        // Validate email before proceeding
        if (!validateEmail(email)) return;

        // Save email to local storage
        localStorage.setItem('resetEmail', email);

        try {
            const response = await axios.post('https://techcogads.com/api/Auth/request-password-reset', { email });

            if (response.status !== 200) {
                throw new Error('Failed to send password reset email');
            }

            setMessage('Password reset email sent! Check your inbox.');
            setTimeout(() => {
                setMessage('');
                navigate('/login/reset-password/newPassword');
            }, 3000); // Redirect to the new password page after 3 seconds
        } catch (error) {
            setError('Failed to send password reset email. Please try again.');
            setTimeout(() => setError(''), 3000); // Clear the error after 3 seconds
        }
    };

    return (
        <>
            {message && (
                <div className="flex items-center justify-center mt-6">
                    <div className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div>
                            <span className="font-medium">{message}</span>
                        </div>
                    </div>
                </div>
            )}

            {error && (
                <div className="flex items-center justify-center mt-6">
                    <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div>
                            <span className="font-medium">{error}</span>
                        </div>
                    </div>
                </div>
            )}

            <section className="font-abc flex items-center justify-center min-h-screen">
                <div className="container mx-auto max-w-screen-xl flex flex-col md:flex-row items-center justify-center">

                    <div className="md:w-1/2">
                        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                            <a className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                                <img className="w-13 h-14 bg-black" src={logo} alt="logo" />
                            </a>
                            <div className="w-full bg-white rounded-lg dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-blue-500/70">
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Reset your Password
                                    </h1>
                                    <form onSubmit={handleResetPassword} className="space-y-4 md:space-y-6">
                                        <div>
                                            <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                            <input
                                                type="email"
                                                autoComplete="username"
                                                name="email"
                                                id="email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    validateEmail(e.target.value);
                                                }}
                                                onBlur={(e) => validateEmail(e.target.value)}
                                                className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'border-red-500' : ''}`}
                                                placeholder="name@company.com"
                                                required
                                            />
                                            {errors.email && (
                                                <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                    {errors.email}
                                                </div>
                                            )}
                                        </div>
                                        <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send Reset Email</button>

                                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                            Remember your password? <button type="button" onClick={() => navigate("/login")} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Back to Login</button>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ResetPassword;
