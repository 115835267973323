import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import { HiOutlineEmojiSad } from "react-icons/hi";
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from '../../images/logo.png';
const Ads = () => {
    const Navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [isError, setIsError] = useState('');
    const { id } = useParams();
    const [showAllAds, setShowAllAds] = useState(false); // State to control whether to show all ads or not

    // useEffect(() => {
    //     AOS.init({
    //         duration: 2000,
    //         offset: 100,
    //     })
    // }, [])

    useEffect(() => {
        axios.get('https://techcogads.com/api/Products/GetAllProductsTotally')
            .then(response => {
                const productsArray = Array.isArray(response.data) ? response.data : [];
                setProducts(productsArray);
                setIsError(false);
            })
            .catch(error => {
                setIsError(true);
                console.error('Error fetching products:', error);
            });
    }, []);

    console.log('Products:', products); // Debug: Log products state

    if (isError) {
        return (
            <section className="bg-white dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 relative flex flex-col justify-center items-center h-screen">
                    <div className="mx-auto max-w-screen-sm text-center">
                        <div className="flex items-center justify-center mb-4">
                            <HiOutlineEmojiSad className="text-4xl lg:text-6xl text-primary-600 dark:text-primary-500" />
                            <h1 className="ml-2 text-4xl tracking-tight font-bold lg:text-6xl text-primary-600 dark:text-primary-500">Oops!</h1>
                        </div>
                        <p className="mb-4 text-2xl tracking-tight font-bold text-gray-900 md:text-2xl dark:text-white">Something's missing.</p>
                        <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page.</p>
                        <Link to="/" className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:focus:ring-primary-900 my-4">Back to Homepage</Link>
                    </div>
                </div>
            </section>
        );
    }

    if (products.length === 0) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="flex justify-center items-center">
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div

                className='font-arapey scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700'
            >
                <div className='container mx-auto max-w-screen-xl'>
                    <div>
                        {/* Button */}
                        <div className=" p-4 flex justify-between items-center">
                            <h1 className="px-2 py-1 text-gray-600 font-semibold text-2xl">Recent Ads</h1>
                            <div className="space-x-2">
                                <div className="w-full h-40 flex items-center justify-center cursor-pointer">
                                    <div
                                        className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold shadow transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 dark:bg-white dark:text-gray-500 dark:hover:text-gray-200 dark:shadow-none group"
                                    >
                                        <span
                                            className="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-blue-600 group-hover:h-full"
                                        ></span>
                                        <span
                                            className="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                fill="none"
                                                className="w-5 h-5 text-blue-600"
                                            >
                                                <path
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                    strokeWidth="2"
                                                    strokeLinejoin="round"
                                                    strokeLinecap="round"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span
                                            className="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                fill="none"
                                                className="w-5 h-5 bg-blue-600 text-gray-100"
                                            >
                                                <path
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                    strokeWidth="2"
                                                    strokeLinejoin="round"
                                                    strokeLinecap="round"
                                                ></path>
                                            </svg>
                                        </span>
                                        <button type='button' onClick={() => Navigate("/all-items")}
                                            className="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white dark:group-hover:text-gray-100">See All Ads
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Category */}
                        <section className="text-gray-600 body-font">
                            <div className="container px-5 pb-6 mx-auto">
                                <div className="flex flex-wrap -m-1">
                                    {products.slice(0, 12).map((post) => {
                                        return (
                                            <Link to={`/all-items/singleItem/${post.productId}`} key={post.productId} className="p-4 md:w-1/4 w-full hover:shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                                                <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                                    <div className="relative group lg:h-48 md:h-36 w-full overflow-hidden">
                                                        <img
                                                            className="h-full w-full object-cover object-center transform scale-100 transition duration-300 ease-in-out hover:scale-110"
                                                            src={`https://techcogads.com/${post.imagePath}`} // Adjust as per your image path structure
                                                            alt="product"
                                                            onError={(e) => {
                                                                console.log('Error loading image:', e.target.src);
                                                                e.target.src = logo; // Placeholder image or handle error state
                                                            }}
                                                        />
                                                        <button type="button" className="absolute top-2 left-2 focus:outline-none text-black bg-yellow-300 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-3 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">
                                                            Featured
                                                        </button>
                                                    </div>
                                                    <div className="p-6">
                                                        <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">Rs {post.price}</h2>
                                                        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">{post.name}</h1>
                                                        <p className="leading-relaxed mb-3">{post.description}</p>
                                                        <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">{post.categoryName.toUpperCase()}</h2>
                                                        <div className="flex items-center flex-wrap">
                                                            {/* Additional content if needed */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ads;
