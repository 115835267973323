import React, { useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Img from '../../images/contact.png';
import { Link } from 'react-router-dom';
import Banner from '../../images/Sample1.png'; // Ensure the path is correct
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = ({ setProgress }) => {
    const form = useRef();

    // For Loader
    useEffect(() => {
        setProgress(40);

        setTimeout(() => {
            setProgress(80);
        }, 1000);
        setTimeout(() => {
            setProgress(100);
        }, 2000);

    }, [])

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_o26805i', 'template_dur5yaa', form.current, {
                publicKey: 'rzSxrek_1u7GuiDs5',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    toast("Response Submitted");
                    e.target.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
            <div>
                <div className="relative w-full h-1/4 md:h-1/4 lg:h-1/4 xl:h-1/4 font-abc scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700">
                    <div className="absolute inset-0 bg-black opacity-60 z-10"></div>
                    <img
                        src={Banner}
                        alt='Add Slider'
                        className="absolute inset-0 w-full h-full object-cover z-0"
                    />
                    <section className="relative z-20 flex items-center justify-center w-full h-full">
                        <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-36">
                            <p className="mt-2 font-bold text-4xl text-white lg:text-md sm:px-16 lg:px-48">
                                Contact US
                            </p>
                            <div className="mx-auto mt-1 bg-blue-500 h-1 w-32"></div>
                            <p className="mt-2 font-normal text-white lg:text-md sm:px-16 lg:px-48">
                                <nav className="flex mx-auto max-w-screen-xl px-4 font-abc mt-3 ml-4" aria-label="Breadcrumb">
                                    <ol className="inline-flex items-center justify-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                                        <li className="inline-flex items-center">
                                            <Link to="/" className="inline-flex items-center text-sm font-medium text-white hover:text-blue-400">
                                                <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                                </svg>
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                                </svg>
                                                <Link to="/contact" className="ms-1 text-sm font-medium text-white hover:text-blue-400 md:ms-2">Contact</Link>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                            </p>
                        </div>
                    </section>
                </div>
            </div>

            <div className="flex flex-col md:flex-row mt-16 py-5 bg-gray-50 font-abc px-5">
                <div className="md:w-1/2 flex justify-center md:justify-start">
                    <img className="py-8" src={Img} alt="logo" />
                </div>

                <div className="md:w-1/2 ">
                    <section className="text-gray-600 body-font relative font-abc">
                        <div className="flex flex-col text-center w-full mb-6">
                            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Contact Us</h1>
                            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Got any Qestions? Want to send feedback about Pak Hoardings? Need details about our Business plan? Let us know.</p>
                        </div>
                        <div className="lg:w-1/2 md:w-2/3 mx-auto">
                            <form className="flex flex-wrap" action="#" ref={form} onSubmit={sendEmail} >
                                <div className="p-2  w-full">
                                    <div className="relative">
                                        <label htmlFor="name" className="leading-7 text-sm text-gray-600 font-semibold">Name</label>
                                        <input type="text" id="name" name="from_name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="What Should We Call You?" required />
                                    </div>
                                </div>
                                <div className="p-2 w-full">
                                    <div className="relative">
                                        <label htmlFor="email" className="leading-7 text-sm text-gray-600 font-semibold">Email</label>
                                        <input type="email" id="email" name="from_email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="example@pakhoardings.com" required />
                                    </div>
                                </div>
                                <div className="p-2 w-full">
                                    <div className="relative">
                                        <label htmlFor="message" className="leading-7 text-sm text-gray-600 font-semibold">Message</label>
                                        <textarea id="message" name='message' className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" placeholder="Leave a comment..." required></textarea>
                                    </div>
                                </div>
                                <div className="p-2 w-full border-t border-gray-200">
                                    <button type="submit" className="mt-3 flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                                        Submit
                                    </button>
                                </div>
                                <ToastContainer />
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Contact;
