import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './Category.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import imgSrc from '../../images/billboard.png'; // Replace with the correct path to your image
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BiCategoryAlt } from "react-icons/bi";

// import categicon1 from '../../images/Billboard1.jpg';
// import categicon2 from '../../images/Billboard2.jpg';
// Add more imports as needed

// const iconMap = {
//     1: categicon1,
//     2: categicon2,
//     // Add more mappings as needed
// };

const CateSlider = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get('https://techcogads.com/api/Categories/GetAllCategories');
            setCategories(response.data); // Assuming response.data is an array of category objects
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    // const getCategoryIcon = (categoryId) => {
    //     const icon = iconMap[categoryId] || null;
    //     console.log(`Category ID: ${categoryId}, Icon: ${icon}`);
    //     return icon;
    // };

    const settings = {
        infinite: true,
        slidesToScroll: 4,
        slidesToShow: 4,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 4000,
        cssEase: 'ease-in-out',
        prevArrow: <></>, // Pass empty fragment to remove previous arrow
        nextArrow: <></>, // Pass empty fragment to remove next arrow
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="mx-auto font-arapey bg-gray-100">
            <Slider {...settings}>
                {categories.map((category) => (
                    <div key={category.categoryId} className="rounded-full w-30 h-30 flex flex-col justify-center items-center mt-14">
                        <Link to={`/all-items`} className="flex flex-col items-center">
                            {/* <img
                                alt="category-icon"
                                className="w-24 h-24 object-cover size-px text-center hover:scale-125 duration-200"
                                src={getCategoryIcon(category.categoryId)}
                                style={{ border: '1px solid red' }} // Debugging style
                                onError={(e) => {
                                    e.target.onerror = null; // Prevents looping
                                    e.target.src = 'path/to/defaultIcon.jpg'; // Replace with actual path to a default icon
                                }}
                            /> */}
                            <section id="cards" className="cards section">
                                <div className="container">
                                    <div className="row no-gutters">
                                        {/* Card 1 */}
                                        <div className="card" >
                                            <div>
                                                <h4>{category.name}</h4>
                                            </div>
                                            <img src={imgSrc} alt="Card Icon" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Link>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default CateSlider;
