import React from 'react';

const FeaturedAdsUser = () => {
    return (
        <div>
            <section className="bg-white dark:bg-gray-900 font-arapey">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">No Featured Ads</h2>
                        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">What are you waiting for... Feature your Ad and grow your business</p>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default FeaturedAdsUser;