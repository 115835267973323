import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import logo from '../../../images/logo.png';
import Cookies from 'js-cookie';

const RoleTable = () => {
    const [errors, setErrors] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [successAlert, setSuccessAlert] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [users, setUsers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        phoneNo: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: '',
        userrole: '',
        newRole: '',
    });
    const [viewMode, setViewMode] = useState(true);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showDangerAlert, setShowDangerAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const fileInputRef = useRef(null);

    const resetForm = () => {
        setFormData({
            username: '',
            phoneNo: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: '',
            userrole: '',
        });
        setErrors({});
        setSuccessAlert('');
        setMessage('');
        setError('');
    };

    const validateUsername = (username) => {
        if (username.length < 3) {
            setErrors(prevErrors => ({ ...prevErrors, username: 'Username must be at least 3 characters long.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, username: '' }));
        }
    };

    const validatePhoneNo = (phoneNo) => {
        const phoneRegex = /^03\d{9}$/;
        if (!phoneRegex.test(phoneNo)) {
            setErrors(prevErrors => ({ ...prevErrors, phoneNo: 'Phone number must contain 11 digits and start with 03.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, phoneNo: '' }));
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrors(prevErrors => ({ ...prevErrors, email: 'Invalid email address.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, email: '' }));
        }
    };

    const validatePassword = (password) => {
        if (password.length < 6) {
            setErrors(prevErrors => ({ ...prevErrors, password: 'Password must be at least 6 characters long.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, password: '' }));
        }
    };

    const validateConfirmPassword = (confirmPassword) => {
        if (confirmPassword !== formData.password) {
            setErrors(prevErrors => ({ ...prevErrors, confirmPassword: 'Passwords do not match.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, confirmPassword: '' }));
        }
    };

    const handleRoleSelection = (selectedRole) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            role: selectedRole
        }));
        setDropdownOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: name === 'phoneNo' ? value.toString() : value
        }));

        if (name === 'username') validateUsername(value);
        if (name === 'phoneNo') validatePhoneNo(value);
        if (name === 'email') validateEmail(value);
        if (name === 'password') validatePassword(value);
        if (name === 'confirmPassword') validateConfirmPassword(value);
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!Object.values(errors).some(error => error !== '')) {
            try {
                const response = await axios.post('https://techcogads.com/api/Auth/register', formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 200) {
                    const { password, confirmPassword, ...dataToStore } = formData;
                    localStorage.setItem('signupData', JSON.stringify(dataToStore));

                    resetForm();
                    setMessage('User Registered successfully!');
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    const errorData = response.data;
                    throw new Error(`Error: ${errorData.message}`);
                }
            } catch (error) {
                setError('Register failed. Please check your details and try again.');
                console.error('Signup Error:', error);
                setTimeout(() => {
                    setError('');
                }, 3000);
            }
        }
    };

    const openEditModal = (user) => {
        setSelectedCategory(user);
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    };

    useEffect(() => {
        if (showEditModal && selectedCategory) {
            const fetchCategoryById = async () => {
                try {
                    const response = await axios.get(`https://techcogads.com/api/Auth/get-user/${selectedCategory.id}`);
                    setFormData({
                        ...formData,
                        userrole: response.data.role
                    });
                    console.log("response:", response.data)
                } catch (error) {
                    console.error('Error fetching category details:', error);
                }
            };

            fetchCategoryById();
        }
    }, [showEditModal, selectedCategory]);

    const getToken = () => {
        return Cookies.get('token');
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = getToken();
                const response = await axios.get('https://techcogads.com/api/Auth/allUsers', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleUpdateRole = async (e) => {
        e.preventDefault();
        const token = getToken();

        try {
            await axios.put(`https://techcogads.com/api/Auth/edit-role/${selectedCategory.id}`, { newRole: formData.userrole }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }

            });

            setShowSuccessAlert(true);
            setAlertMessage('User role updated successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
            closeEditModal();
        } catch (error) {
            setShowDangerAlert(true);
            setAlertMessage('Failed to update user role. Please try again.');
            setTimeout(() => {
                setShowDangerAlert(false);
                setAlertMessage('');
            }, 3000);
        }
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setSelectedProduct(null);
        setFormData({
            username: '',
            phoneNo: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: ''
        });
        setViewMode(false);
    };

    // Render loading indicator if products are still loading
    if (users.length === 0) {
        return (
            <div className="flex justify-center items-center">
                <div role="status">
                    <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                </div>
            </div>
        );
    }

    return (
        <div className="relative overflow-x-auto font-arapey">
            <button
                onClick={() => { setViewMode(false); toggleModal(); }}
                className="mb-2 block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
            >
                Register User
            </button>

            {/* Show success or danger alert based on API response */}
            {showSuccessAlert && (
                <div className="fixed top-0 left-0 right-0 bg-green-500 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            {showDangerAlert && (
                <div className="fixed top-0 left-0 right-0 bg-red-500 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            {/* Alerts */}
            {showSuccessAlert && (
                <div className="fixed top-0 left-0 right-0 bg-green-500 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            {showDangerAlert && (
                <div className="fixed top-0 left-0 right-0 bg-red-500 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            {/* User Table */}
            {users.length === 0 ? (
                <div>
                    <section className="bg-white dark:bg-gray-900">
                        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">No Ad Yet!</h2>
                                <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">What are you waiting for... Post your Ad and grow your business</p>
                            </div>

                        </div>
                    </section>
                </div>
            ) : (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Name</th>
                            <th scope="col" className="px-6 py-3">User ID</th>
                            <th scope="col" className="px-6 py-3">Email</th>
                            <th scope="col" className="px-6 py-3">Mobile No</th>
                            <th scope="col" className="px-6 py-3">Role</th>
                            <th scope="col" className="px-6 py-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((users) => (
                            <tr key={users.userId} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="px-6 py-4">{users.userName}</td>
                                <td className="px-6 py-4">{users.id}</td>
                                <td className="px-6 py-4">{users.email}</td>
                                <td className="px-6 py-4">{users.phoneNo}</td>
                                <td className="px-6 py-4">{users.role}</td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() => openEditModal(users)}
                                        className="text-blue-600 hover:text-blue-800 ml-4"
                                    >
                                        <FaEdit />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {showEditModal && (
                <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Edit User Role
                                </h3>
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={closeEditModal}>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6-6m-6 6l6 6m-6-6L1 13" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-4 md:p-6">
                                <form onSubmit={handleUpdateRole}>
                                    <div className="mb-4">
                                        <label htmlFor="userrole" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                                            Role
                                        </label>
                                        <input
                                            type="text"
                                            id="userrole"
                                            name="userrole"
                                            value={formData.userrole}
                                            onChange={handleChange}
                                            className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Enter new role"
                                        />
                                    </div>
                                    <div className="flex items-center justify-end space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                        <button type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={closeEditModal}>
                                            Cancel
                                        </button>
                                        <button type="submit" className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800">
                                            Update Role
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Add User Modal */}
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg mx-4 mt-96 sm:mt-24 md:mt-32 lg:mt-48 xl:mt-64">
                        <form onSubmit={handleSignup}>
                            <h1 className="mt-24 text-3xl text-center text-black font-extrabold underline rounded-r-lg">
                                Register User
                            </h1>
                            <div className="mb-4 mt-16">
                                <div className='mb-3'>
                                    <label htmlFor="username" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                    <input
                                        type="text"
                                        name="username"
                                        id="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter your username"
                                        required
                                    />
                                    {errors.username && (
                                        <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                            {errors.username}
                                        </div>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="phoneNo" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                                    <input
                                        type="tel"
                                        name="phoneNo"
                                        id="phoneNo"
                                        value={formData.phoneNo}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter your phone number"
                                        required
                                    />
                                    {errors.phoneNo && (
                                        <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                            {errors.phoneNo}
                                        </div>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input
                                        type="email"
                                        autoComplete="username"
                                        name="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="name@company.com"
                                        required
                                    />
                                    {errors.email && (
                                        <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                            {errors.email}
                                        </div>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter your password"
                                        required
                                    />
                                    {errors.password && (
                                        <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                            {errors.password}
                                        </div>
                                    )}
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="confirmPassword" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Re-enter your password"
                                        required
                                    />
                                    {errors.confirmPassword && (
                                        <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                            {errors.confirmPassword}
                                        </div>
                                    )}
                                </div>
                                <div className='mb-4'>
                                    <label htmlFor="role" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                                    <div className="relative">
                                        <button
                                            type="button"
                                            onClick={() => setDropdownOpen(!dropdownOpen)}
                                            aria-haspopup="listbox"
                                            aria-expanded="true"
                                            className="w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 flex items-center justify-between"
                                        >
                                            {formData.role || 'Select Role'}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5 text-gray-500"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                        {dropdownOpen && (
                                            <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
                                                <ul
                                                    tabIndex="-1"
                                                    role="listbox"
                                                    type="text"
                                                    name="role"
                                                    id="role"
                                                    aria-labelledby="listbox-label"
                                                    aria-activedescendant="listbox-option-3"
                                                    className="py-1 overflow-auto text-base ring-1 ring-black ring-opacity-5 max-h-60 rounded-md shadow-xs focus:outline-none sm:text-sm"
                                                >
                                                    <li
                                                        onClick={() => handleRoleSelection('Advertiser')}
                                                        className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                                    >
                                                        <div className="flex items-center">
                                                            <span className="ml-3 block font-normal truncate">Advertiser</span>
                                                        </div>
                                                    </li>
                                                    <li
                                                        onClick={() => handleRoleSelection('User')}
                                                        className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                                    >
                                                        <div className="flex items-center">
                                                            <span className="ml-3 block font-normal truncate">User</span>
                                                        </div>
                                                    </li>
                                                    <li
                                                        onClick={() => handleRoleSelection('Admin')}
                                                        className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9"
                                                    >
                                                        <div className="flex items-center">
                                                            <span className="ml-3 block font-normal truncate">Admin</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button
                                        type="button"
                                        onClick={toggleModal}
                                        className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="px-4 py-2  mr-2 bg-primary-600 hover:bg-primary-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-opacity-50 transition-all ease-in-out duration-300"
                                    >
                                        Create Account
                                    </button>
                                </div>
                            </div>
                        </form>
                        {message && (
                            <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white text-center py-2 z-50">
                                {message}
                            </div>
                        )}
                        {error && (
                            <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-red-400 via-red-500 to-red-600 text-white text-center py-2 z-50">
                                {error}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RoleTable;
