import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/logo.png';

const ResetPasswordCode = ({ setProgress }) => {
    const [code, setResetCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    // For Loader
    useEffect(() => {
        setProgress(40);

        setTimeout(() => {
            setProgress(80);
        }, 1000);
        setTimeout(() => {
            setProgress(100);
        }, 2000);

    }, [])

    useEffect(() => {
        const storedEmail = localStorage.getItem('resetEmail');
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []);

    const validateResetCode = (code) => {
        const codeRegex = /^\d{6}$/;
        if (!codeRegex.test(code)) {
            setErrors(prevErrors => ({ ...prevErrors, code: 'Reset code must be 6 digits long.' }));
            return false;
        } else {
            setErrors(prevErrors => ({ ...prevErrors, code: '' }));
            return true;
        }
    };

    const validatePassword = (password) => {
        if (password.length < 6) {
            setErrors(prevErrors => ({ ...prevErrors, newPassword: 'Password should be at least 6 characters long.' }));
            return false;
        } else {
            setErrors(prevErrors => ({ ...prevErrors, newPassword: '' }));
            return true;
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();

        // Validate reset code and password before proceeding
        if (!validateResetCode(code) || !validatePassword(newPassword)) return;

        try {
            const response = await axios.post('https://techcogads.com/api/Auth/reset-password', {
                email,
                code,
                newPassword
            });

            if (response.status !== 200) {
                throw new Error('Failed to reset password');
            }

            setMessage('Password has been reset successfully!');
            setTimeout(() => {
                setMessage('');
                navigate('/login');
            }, 3000); // Redirect to login after 3 seconds
        } catch (error) {
            setError('Failed to reset password. Please check the reset code and try again.');
            setTimeout(() => setError(''), 3000); // Clear the error after 3 seconds
        }
    };

    return (
        <>
            {message && (
                <div className="flex items-center justify-center mt-6">
                    <div className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div>
                            <span className="font-medium">{message}</span>
                        </div>
                    </div>
                </div>
            )}

            {error && (
                <div className="flex items-center justify-center mt-6">
                    <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div>
                            <span className="font-medium">{error}</span>
                        </div>
                    </div>
                </div>
            )}

            <section className="font-abc flex items-center justify-center min-h-screen">
                <div className="container mx-auto max-w-screen-xl flex flex-col md:flex-row items-center justify-center">

                    <div className="md:w-1/2">
                        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                            <a className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                                <img className="w-13 h-14 bg-black" src={logo} alt="logo" />
                            </a>
                            <div className="w-full bg-white rounded-lg dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-blue-500/70">
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Enter Reset Code and New Password
                                    </h1>
                                    <form onSubmit={handlePasswordReset} className="space-y-4 md:space-y-6">
                                        <div>
                                            <label htmlFor="code" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Reset Code</label>
                                            <input
                                                type="text"
                                                name="code"
                                                id="code"
                                                value={code}
                                                onChange={(e) => setResetCode(e.target.value)}
                                                onBlur={(e) => validateResetCode(e.target.value)}
                                                className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.resetcode ? 'border-red-500' : ''}`}
                                                placeholder="Enter your reset code"
                                                required
                                            />
                                            {errors.resetCode && (
                                                <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                    {errors.code}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <label htmlFor="newPassword" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                                            <input
                                                type="password"
                                                autoComplete="new-password"
                                                name="newPassword"
                                                id="newPassword"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                onBlur={(e) => validatePassword(e.target.value)}
                                                className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.newPassword ? 'border-red-500' : ''}`}
                                                placeholder="Enter new password"
                                                required
                                            />
                                            {errors.newPassword && (
                                                <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                    {errors.newPassword}
                                                </div>
                                            )}
                                        </div>
                                        <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Reset Password</button>

                                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                            Remember your password? <button type="button" onClick={() => navigate("/login")} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Back to Login</button>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ResetPasswordCode;
