import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';

const CategoryTable = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
    });
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showDangerAlert, setShowDangerAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [validationError, setValidationError] = useState('');
    const fileInputRef = useRef(null);

    // if (fileInputRef.current.files.length > 0) {
    //     formData.append('image', fileInputRef.current.files[0]);
    // }

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://techcogads.com/api/Categories/GetAllCategories');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        if (showEditModal && selectedCategory) {
            const fetchCategoryById = async () => {
                try {
                    const response = await axios.get(`https://techcogads.com/api/Categories/GetCategoryById?id=${selectedCategory.categoryId}`);
                    setFormData({
                        name: response.data.name,
                        'Content-Type': 'multipart/form-data'
                    });
                } catch (error) {
                    console.error('Error fetching category details:', error);
                }
            };

            fetchCategoryById();
        }
    }, [showEditModal, selectedCategory]);

    const handleDelete = async (categoryId) => {
        try {
            await axios.post(`https://techcogads.com/api/Categories/DeleteCategoryById?categoryId=${categoryId}`);
            setCategories(categories.filter(category => category.categoryId !== categoryId));
            setShowSuccessAlert(true);
            setAlertMessage('Category Deleted successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 5000);
            setShowDeleteModal(false);
        } catch (error) {
            console.error('Error deleting category:', error);
            setShowDangerAlert(true);
            setAlertMessage('Failed to Delete category. Please try again.');
        }
    };

    const openEditModal = (category) => {
        setSelectedCategory(category);
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    };

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        const { name } = formData;
        const { image } = formData
        const categoryId = selectedCategory.categoryId;

        if (name.length < 3) {
            setValidationError('Category name must be more than 3 characters.');
            return;
        }

        try {
            await axios.put('https://techcogads.com/api/Categories/UpdateCategoryById', {
                categoryId: categoryId,
                newName: name,
                NewImg: image
            });
            setAlertMessage('Category updated successfully.');
            setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            setShowEditModal(false);

            // Update categories state with the new name
            setCategories(prevCategories => (
                prevCategories.map(category => {
                    if (category.categoryId === categoryId) {
                        return {
                            ...category,
                            name: name,
                            image: image
                        };
                    }
                    return category;
                })
            ));
        } catch (error) {
            console.error('Error editing category:', error);
            setShowDangerAlert(true);
            setAlertMessage('Failed to update category. Please try again.');
        }
    };

    const openAddModal = () => {
        setShowAddModal(true);
    };

    const closeAddModal = () => {
        setShowAddModal(false);
    };

    const handleAddSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append('name', formData.name);

        if (fileInputRef.current.files.length > 0) {
            data.append('image', fileInputRef.current.files[0]);
        }

        try {
            await axios.post('https://techcogads.com/api/Categories/PostCategory', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setShowSuccessAlert(true);
            setAlertMessage('Category added successfully.');
            setTimeout(() => {
                setShowSuccessAlert(false);
                setAlertMessage('');
            }, 3000);
            window.location.reload();
            setShowAddModal(false);
        } catch (error) {
            console.error('Error adding category:', error);
            setShowDangerAlert(true);
            setAlertMessage('Failed to add category. Please try again.');
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: value
        }));
        setValidationError(''); // Clear validation error on input change
    };

    const openDeleteModal = (category) => {
        setSelectedCategory(category);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    // Render loading indicator if products are still loading
    if (categories.length === 0) {
        return (
            <div className="flex justify-center items-center">
                <div role="status">
                    <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                </div>
            </div>
        );
    }

    return (
        <div className="relative overflow-x-auto font-arapey">
            <button type="button" onClick={openAddModal} className="text-white mb-4 bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2">
                Add Category
            </button>

            {/* Alerts */}
            {showSuccessAlert && (
                <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}
            {showDangerAlert && (
                <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-red-400 via-red-500 to-red-600 text-white text-center py-2 z-50">
                    {alertMessage}
                </div>
            )}

            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="md:hidden sm:hidden lg:hidden xl:hidden px-6 py-3">
                            Sr. no
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Category Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Products
                        </th>
                        {/* <th scope="col" className="px-6 py-3">
                            Image
                        </th> */}
                        <th scope="col" className="px-6 py-3">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((category, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className="px-6 py-4 md:hidden">
                                {category.categoryId}
                            </td>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {category.name}
                            </th>
                            <td className="px-8 py-4">
                                {category.productCount}
                            </td>
                            {/* <td className="px-6 py-4">
                                <img src={`https://techcogads.com/${category.imagePath}`} alt={category.name} className="w-20 h-20 object-cover" />
                            </td> */}
                            <td className="px-7 py-4">
                                <div className="flex items-center gap-1">
                                    <FaEdit
                                        className="text-blue-600 hover:text-blue-800 cursor-pointer"
                                        onClick={() => openEditModal(category)}
                                    />
                                    <RiDeleteBin6Line className="text-red-600 hover:text-red-800 ml-2 cursor-pointer"
                                        onClick={() => openDeleteModal(category)}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Edit Category */}
            {showEditModal && (
                <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Edit Category
                                </h3>
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={closeEditModal}>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <form onSubmit={handleEditSubmit} className="p-4 md:p-5">
                                <div className="grid gap-4 mb-4 grid-cols-2">
                                    <div className="col-span-2">
                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                            placeholder="Enter Category changes"
                                            required
                                        />
                                    </div>
                                    {/* <div className="col-span-2">
                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category Image</label>
                                        <input className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                            type="file"
                                            id="image"
                                            name="image"
                                            required
                                        />
                                    </div> */}
                                </div>
                                <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                    Save Changes
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Add Category */}
            {showAddModal && (
                <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Add Category
                                </h3>
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={closeAddModal}>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <form onSubmit={handleAddSubmit} className="p-4 md:p-5">
                                <div className="grid gap-4 mb-4 grid-cols-2">
                                    <div className="col-span-2">
                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                            placeholder="Enter Category Name"
                                            required
                                        />
                                        {validationError && <p className="text-red-500 text-sm mt-1">{validationError}</p>}
                                    </div>

                                    {/* <div className="col-span-2">
                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category Image</label>
                                        <input className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                            type="file"
                                            id="image"
                                            name="image"
                                            ref={fileInputRef}
                                            required
                                        />
                                    </div> */}
                                </div>
                                <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                    Add Category
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Category */}
            {showDeleteModal && (
                <div id="delete-modal" tabIndex="-1" aria-hidden="true" className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Delete Category
                                </h3>
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={closeDeleteModal}>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-4 md:p-5">
                                <p>Are you sure you want to delete this category?</p>
                                <div className="mt-4 flex justify-end">
                                    <button type="button" className="text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm px-4 py-2 me-2 dark:hover:bg-gray-600 dark:hover:text-white" onClick={closeDeleteModal}>
                                        Cancel
                                    </button>
                                    <button type="button" className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900" onClick={() => handleDelete(selectedCategory.categoryId)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoryTable;

