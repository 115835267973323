/* App.js */
import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');


var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class App extends Component {
    render() {
        const options = {
            animationEnabled: true,
            exportEnabled: false,
            theme: "light1", // "light1", "dark1", "dark2"
            title: {
                text: "New Users & Guest Users",
            },
            data: [{
                type: "pie",
                indexLabel: "{label}: {y}%",
                startAngle: -90,
                dataPoints: [
                    { y: 25, label: "New Users" },
                    { y: 20, label: "Guest users" },
                    { y: 25, label: "Ads" },
                    { y: 15, label: "Categories" },
                    { y: 15, label: "Featured" },
                ]
            }]
        }

        return (
            <div className='font-arapey'>
                <CanvasJSChart options={options}
                /* onRef={ref => this.chart = ref} */
                />
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }
}

export default App;       