import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie
import logo from '../images/logo.png';
import Img from '../images/sign-in.png';

const Login = ({ setProgress }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    let logoutTimer;

    // For Loader
    useEffect(() => {
        setProgress(40);

        setTimeout(() => {
            setProgress(80);
        }, 1000);
        setTimeout(() => {
            setProgress(100);
        }, 2000);

    }, [])

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrors(prevErrors => ({ ...prevErrors, email: 'Invalid email address.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, email: '' }));
        }
    };

    const validatePassword = (password) => {
        if (password.length < 6) {
            setErrors(prevErrors => ({ ...prevErrors, password: 'Password must be at least 6 characters long.' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, password: '' }));
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!Object.values(errors).some(error => error !== '')) {
            // Validation passed, proceed with login
            try {
                const response = await axios.post('https://techcogads.com/api/Auth/login', {
                    email,
                    password,
                });

                if (response.status === 200) {
                    // Handle successful login
                    const { token, email, userName, role } = response.data; // Assuming API response contains these fields
                    Cookies.set('token', token, { expires: 1 / 24 }); // Expires in 1 hour
                    localStorage.setItem('email', email);
                    localStorage.setItem('userName', userName);
                    localStorage.setItem('role', role);
                    localStorage.setItem('justLoggedIn', 'true');

                    // Set a timeout to log out after one hour
                    logoutTimer = setTimeout(() => {
                        logout();
                    }, 1 * 3600000); // 1 minute in milliseconds for testing purposes

                    navigate('/');
                } else {
                    setError('Login failed. Please Try Again.');
                    setTimeout(() => {
                        setError('');
                    }, 3000); // Clear error after 3 seconds
                }
            } catch (error) {
                // Error handling
                setError(`Login failed. Please Try Again.`);
                setTimeout(() => {
                    setError('');
                }, 3000); // Clear error after 3 seconds
                console.error('Login Error:', error);
            }
        }
    };

    const logout = () => {
        Cookies.remove('token');
        localStorage.removeItem('email');
        localStorage.removeItem('userName');
        localStorage.removeItem('role');
        navigate('/login'); // Redirect to the login page
        clearTimeout(logoutTimer); // Clear the logout timer
    };

    const getToken = () => {
        return Cookies.get('token');
    };

    const fetchAllProducts = async () => {
        const token = getToken(); // Retrieve the token from the cookie

        if (!token) {
            console.error("No token found");
            return;
        }

        const headers = {
            Authorization: `Bearer ${token}`,
            // Add other headers as needed
        };

        try {
            const response = await fetch('https://techcogads.com/api/GetAllProducts', { headers });

            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }

            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <section className='font-abc'>
            <div className="container mx-auto max-w-screen-xl flex flex-col md:flex-row">
                <div className="hidden md:block md:w-1/2 lg:flex justify-center md:justify-start">
                    <img className="py-10 max-w-full h-auto" src={Img} alt="logo" />
                </div>

                <div className="md:w-1/2">
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 md:space-y-6">
                        <a className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                            <img className="w-13 h-14 bg-black" src={logo} alt="logo" />
                        </a>
                        <div className="w-full bg-white rounded-lg dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-blue-500/70">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                    Sign in to continue
                                </h1>
                                <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
                                    <div>
                                        <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                validateEmail(e.target.value);
                                            }}
                                            onBlur={(e) => validateEmail(e.target.value)}
                                            className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'border-red-500' : ''}`}
                                            placeholder="name@company.com"
                                            required
                                        />
                                        {errors.email && (
                                            <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                {errors.email}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                validatePassword(e.target.value);
                                            }}
                                            onBlur={(e) => validatePassword(e.target.value)}
                                            className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.password ? 'border-red-500' : ''}`}
                                            placeholder="Enter your password"
                                            required
                                        />
                                        {errors.password && (
                                            <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                {errors.password}
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                                            </div>
                                        </div>
                                        <button type="button" onClick={() => navigate("/login/reset-password")} className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</button>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-4"
                                        >
                                            Login
                                        </button>
                                        {error && (
                                            <div className="flex items-center p-2 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                {error}
                                            </div>
                                        )}
                                    </div>
                                </form>
                                <div className="flex justify-center">
                                    <span className="text-sm text-gray-800 dark:text-gray-200">
                                        Don't have an account?{' '}
                                        <Link to="/signup" className="font-medium text-indigo-600 dark:text-indigo-400 hover:underline">
                                            Signup
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
