import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ErrorPage from './404Page';
import Cookies from 'js-cookie';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import logo from '../../images/logo.png'; // Import your logo image here

const Item = ({ setProgress }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [error, setError] = useState(null);
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [selectedImage, setSelectedImage] = useState(''); // State to track the selected image

    useEffect(() => {
        setProgress(40);
        setTimeout(() => setProgress(80), 1000);
        setTimeout(() => setProgress(100), 2000);
    }, [setProgress]);

    const baseUrl = "https://www.techcogads.com";
    useEffect(() => {
        fetch(`${baseUrl}/api/Products/GetProductById/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch item data');
                }
                return response.json();
            })
            .then(data => {
                setItem(data);
                setSelectedImage(`${baseUrl}${data.imagePath[0]}`); // Set the initial main image
            })
            .catch(error => {
                console.error('Error fetching item:', error);
                setError(error);
            });
    }, [id]);

    const handleShowPhoneNumber = () => {
        const token = Cookies.get('token'); // Retrieve token from cookies
        if (token) {
            setShowPhoneNumber(true);
        } else {
            navigate('/login');
        }
    };

    const handleThumbnailClick = (imageUrl) => {
        setSelectedImage(imageUrl); // Update the main image with the selected thumbnail
    };

    if (error) {
        return <ErrorPage />;
    }

    if (!item) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div role="status">
                    <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="mt-20 w-full font-arapey">
                <div className="relative pb-[50vh] overflow-hidden">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20442.102753180425!2d74.24843407385787!3d31.419748611618836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391901a891ac4ff9%3A0x4cbff22eed0baf99!2sPlot%20571%2C%20WAPDA%20Town%20Block%20G%202%20Wapda%20Town%20Phase%201%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1716638959882!5m2!1sen!2s"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="absolute top-0 left-0 w-full h-full border-0"
                    />
                </div>
            </div>

            <div className='font-arapey h-secreen'>
                <section className="text-gray-600 body-font overflow-hidden">
                    <div className="container px-5 py-24 mx-auto">
                        <div className="lg:w-4/5 mx-auto flex flex-wrap">
                            <div className="lg:w-1/3 w-full flex flex-col items-start">
                                {/* Main Image */}
                                <img
                                    src={selectedImage} // Display the selected image
                                    alt="Main Product"
                                    className="mt-0 md:mt-10 lg:mt-10 xl:mt-10 w-full h-auto object-cover object-center rounded transform transition duration-300 ease-in-out hover:scale-110"
                                />

                                {/* Thumbnail Images */}
                                <div className="flex flex-wrap mt-4">
                                    {item.imagePath.slice(0, 4).map((image, index) => {
                                        const fullImageUrl = `${baseUrl}${image}`;
                                        return (
                                            <div key={index} className="w-1/3 p-1">
                                                <img
                                                    src={fullImageUrl}
                                                    alt={`Product ${index + 2}`} // Adjust the alt text as needed
                                                    className="w-full h-24 object-cover object-center transform transition duration-300 ease-in-out hover:scale-110 cursor-pointer"
                                                    onClick={() => handleThumbnailClick(fullImageUrl)} // Change the main image on click
                                                />
                                            </div>
                                        );
                                    })}
                                </div>


                            </div>

                            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                                <span className="text-sm title-font text-gray-500 tracking-widest">{item.categoryName.toUpperCase()}</span>
                                <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">{item.name}</h1>
                                <span className='text-sm'>Availability From: <span className='text-black'>{item.availabiltyDate.slice(0, 10)}</span></span>
                                <div className="flex mb-4">
                                    <div className="flex items-center">
                                        <h2 className="ms-1 text-sm text-gray-500 dark:text-gray-400">Size:</h2>
                                        <p className="ml-2 text-md title-font text-blue-400 tracking-widest">{item.size}</p>
                                    </div>
                                    <span className="flex ml-3 pl-3 py-2 border-l-2 border-gray-200 space-x-2s">
                                        <a href='#' className='hover:text-blue-400 hover:underline'>
                                            See On Maps
                                        </a>
                                    </span>
                                </div>
                                <p className="leading-relaxed mb-5 border-b-2 border-gray-200">{item.description}</p>
                                <div className="flex mb-2">
                                    <div className="flex items-center">
                                        <p className="text-sm title-font tracking-widest">Traffic Coming From:</p>
                                        <p className="ml-2 text-sm title-font text-blue-500 underline tracking-widest">{item.trafficComingFrom}</p>
                                    </div>
                                </div>
                                <div className="flex mb-2">
                                    <div className="flex items-center">
                                        <p className="text-sm title-font tracking-widest">Traffic Going Towards:</p>
                                        <p className="ml-2 text-sm title-font text-blue-500 underline tracking-widest">{item.trafficGoingTowards}</p>
                                    </div>
                                </div>
                                <div className="flex border-t-2 border-gray-200">
                                    <span className="mt-5 title-font font-medium text-2xl text-gray-900">PKR {item.price}/<span className='text-gray-500 text-sm'>{item.priceOptions}</span></span>
                                    <button
                                        onClick={handleShowPhoneNumber}
                                        className="mt-5 flex ml-auto text-white bg-blue-400 border-0 py-2 px-6 focus:outline-none hover:bg-blue-500 rounded"
                                    >
                                        {showPhoneNumber ? item.mobileNo : 'Show Phone Number'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div>
        </>
    );
};

export default Item;
