import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaRegUserCircle } from "react-icons/fa";
import Cookies from 'js-cookie';
import logo from '../../images/02.png';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
const Nav = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [justLoggedIn, setJustLoggedIn] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    // useEffect(() => {
    //     AOS.init({
    //         duration: 2000,
    //         offset: 100,
    //         once: true,
    //     })
    // }, [])

    useEffect(() => {
        const updateUserFromLocalStorage = () => {
            const email = localStorage.getItem('email');
            const userName = localStorage.getItem('userName');
            const role = localStorage.getItem('role');
            const justLoggedIn = localStorage.getItem('justLoggedIn');

            if (email && role && userName) {
                setUser({ email, role, userName });
                if (justLoggedIn === 'true') {
                    NotificationManager.info('Logged in successfully');
                    localStorage.removeItem('justLoggedIn');
                }
            } else {
                setUser(null);
            }
        };

        updateUserFromLocalStorage();
        window.addEventListener('storage', updateUserFromLocalStorage);

        return () => {
            window.removeEventListener('storage', updateUserFromLocalStorage);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleToggle = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleLogout = () => {
        Cookies.remove('token');
        localStorage.removeItem('email');
        localStorage.removeItem('userName');
        localStorage.removeItem('role');
        setUser(null);
        navigate('/login');
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAdminPanel = () => {
        if (user && user.role === 'Advertiser') {
            navigate('/advertiser/panel');
        } else if (user && user.role === 'Admin') {
            navigate('/admin/dashboard-panel');
        }
        else if (user && user.role === 'User') {
            navigate('/user/panel');
        }
        else {
            alert('Access denied');
        }
    };

    const handlePostAdd = () => {
        if (user) {
            navigate('/add-post');
        } else {
            navigate('/login');
        }
    };



    return (
        <>
            <NotificationContainer />
            <nav className={`fixed w-full z-50 top-0 start-0 font-arapey scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700 transition-colors duration-300 ${isScrolled ? 'bg-[#100d14] opacity-90  ' : 'bg-transparent'}`}>
                <div className="font-arapey max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <NavLink to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={logo} className="h-12" alt="Pak Hoarding Logo" />
                    </NavLink>
                    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                        {user ? (
                            <div className="relative" ref={dropdownRef}>
                                <button
                                    onClick={toggleDropdown}
                                    className="flex items-center mt-1 text-sm focus:ring-4 focus:ring-gray-50 dark:focus:ring-gray-600"
                                >
                                    <span className="sr-only">Open user menu</span>
                                    <FaRegUserCircle className="w-9 h-9 py-1 text-blue-500" />
                                </button>
                                {isDropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg dark:bg-gray-700">
                                        <ul className="py-2">
                                            <li>
                                                <span className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200">
                                                    Hello, <br />
                                                    <div className="block w-full text-lg text-blue-400 dark:text-gray-200">
                                                        {user.userName}
                                                    </div>
                                                </span>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={handleAdminPanel}
                                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                                >
                                                    Admin Panel
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={handleLogout}
                                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                                >
                                                    Sign out
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <button
                                type="button"
                                onClick={() => navigate('/login')}
                                className="bg-blue-600 text-gray-100 focus:ring-4 focus:outline-none font-medium text-sm px-4 py-2 text-center md:hover:bg-blue-400 dark:focus:ring-blue-400 rounded-full"
                            >
                                Sign In | Log In
                            </button>
                        )}
                        <button
                            onClick={handleToggle}
                            type="button"
                            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-blue-500 rounded-lg md:hidden lg:hidden hover:bg-white focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white-400 dark:hover:bg-white dark:focus:ring-white-600"
                            aria-controls="navbar-sticky"
                            aria-expanded={isNavOpen}
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button>
                    </div>
                    <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isNavOpen ? '' : 'hidden'}`} id="navbar-sticky">
                        <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-red-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:dark:bg-white dark:border-white-700">
                            <li>
                                <NavLink
                                    to="/"
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'block py-2 px-3 text-blue-500 font-bold rounded md:bg-transparent md:p-0'
                                            : 'block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0 dark:text-[#6b7280] dark:hover:bg-slate-300 dark:hover:text-blue-500 md:dark:hover:bg-transparent dark:border-gray-700'
                                    }
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/services"
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'block py-2 px-3 text-blue-500 font-bold rounded md:bg-transparent md:p-0'
                                            : 'block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0 dark:text-[#6b7280] dark:hover:bg-slate-300 dark:hover:text-blue-500 md:dark:hover:bg-transparent dark:border-gray-700'
                                    }
                                >
                                    Services
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/add-post"
                                    onClick={handlePostAdd}
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'block py-2 px-3 text-blue-500 font-bold rounded md:bg-transparent md:p-0'
                                            : 'block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0 dark:text-[#6b7280] dark:hover:bg-slate-300 dark:hover:text-blue-500 md:dark:hover:bg-transparent dark:border-gray-700'
                                    }
                                >
                                    Post Ad
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink
                                    to="/about"
                                    className={({ isActive }) => getNavLinkClasses(isActive)}
                                >
                                    About
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink
                                    to="/contact"
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'block py-2 px-3 text-blue-500 font-bold rounded md:bg-transparent md:p-0'
                                            : 'block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-400 md:p-0 dark:text-[#6b7280] dark:hover:bg-slate-300 dark:hover:text-blue-500 md:dark:hover:bg-transparent dark:border-gray-700'
                                    }
                                >
                                    Contact
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Nav;
