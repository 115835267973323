import React from 'react';
import { HiOutlineEmojiSad } from "react-icons/hi";
import { Link } from 'react-router-dom'

const ErrorPage = () => {
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 relative flex flex-col justify-center items-center h-screen">
                <div className="mx-auto max-w-screen-sm text-center">
                    <div className="flex items-center justify-center mb-4">
                        <HiOutlineEmojiSad className="text-4xl lg:text-6xl text-primary-600 dark:text-primary-500" />
                        <h1 className="ml-2 text-4xl tracking-tight font-bold lg:text-6xl text-primary-600 dark:text-primary-500">Oopsiii!</h1>
                    </div>
                    <p className="mb-4 text-2xl tracking-tight font-bold text-gray-900 md:text-2xl dark:text-white">Something's missing.</p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page.</p>
                    <Link to="/" className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:focus:ring-primary-900 my-4">Back to Homepage</Link>
                </div>
            </div>
        </section>
    )
}

export default ErrorPage



