import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineEmojiSad } from "react-icons/hi";
import axios from 'axios';
import ErrorPage from './404Page';
import logo from '../../images/logo.png';

const Items = ({ setProgress }) => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isError, setIsError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true); // Loading state
    const [sortOption, setSortOption] = useState(''); // State for sort option
    const [visibleCount, setVisibleCount] = useState(6); // State for the number of visible products

    const productsPerPage = 6;

    // For Loader
    useEffect(() => {
        setProgress(40);

        setTimeout(() => {
            setProgress(80);
        }, 1000);
        setTimeout(() => {
            setProgress(100);
        }, 2000);

    }, [])

    // Handle "Load More" button click
    const handleLoadMore = () => {
        setVisibleCount(prevCount => prevCount + productsPerPage);
    };

    // Sorting Logic
    const handleSortChange = (e) => {
        setSortOption(e.target.value);
        let sortedProducts = [...filteredProducts];

        switch (e.target.value) {
            case 'price':
                sortedProducts.sort((a, b) => a.price - b.price);
                break;
            case 'size':
                sortedProducts.sort((a, b) => a.size.localeCompare(b.size));
                break;
            case 'name':
                sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case 'category':
                sortedProducts.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
                break;
            case 'description':
                sortedProducts.sort((a, b) => a.description.localeCompare(b.description));
                break;
            default:
                break;
        }

        setFilteredProducts(sortedProducts);
    };

    // Fetch Products
    const baseUrl = "https://www.techcogads.com";

    useEffect(() => {
        axios.get(`${baseUrl}/api/Products/GetAllProductsTotally`)
            .then(response => {
                const productsArray = Array.isArray(response.data) ? response.data : [];
                setProducts(productsArray);
                setFilteredProducts(productsArray); // Assuming you want to filter products
                setIsError(false);
                setLoading(false); // Stop loading once data is fetched
            })
            .catch(error => {
                setIsError(true);
                setLoading(false); // Stop loading in case of error
                console.error('Error fetching products:', error);
            });
    }, []);

    // Fetch Categories
    useEffect(() => {
        axios.get('https://www.techcogads.com/api/Categories/GetAllCategories')
            .then(response => {
                setCategories(response.data || []);
            })
            .catch(error => {
                setIsError(true);
                console.error('Error fetching categories:', error);
            });
    }, []);

    // Fetch products based on search term
    useEffect(() => {
        if (searchTerm) {
            axios.get(`https://www.techcogads.com/api/Products/SearchProducts?query=${searchTerm}`)
                .then(response => {
                    setFilteredProducts(response.data || []);
                    setIsError(false);

                })
                .catch(error => {
                    setIsError(true);
                    console.error('Error fetching search results:', error);
                });
        } else {
            setFilteredProducts(products);
        }
    }, [searchTerm, products]);

    // Update filteredProducts state based on selected category
    useEffect(() => {
        const filtered = selectedCategory
            ? products.filter(product => product.category === selectedCategory)
            : products;
        setFilteredProducts(filtered);
    }, [selectedCategory, products]);

    // Handle category selection
    const handleCategorySelect = (categoryId) => {
        setSelectedCategory(categoryId);
        setSearchTerm(''); // Reset search term when category is selected
        setLoading(true); // Start loading when category is selected

        // Fetch products by selected category
        axios.get(`https://www.techcogads.com/api/Categories/GetProductsByCategory/${categoryId}`)
            .then(response => {
                const { categoryName, products } = response.data;
                // Update filteredProducts state with the products fetched for the selected category
                setFilteredProducts(products.map(product => ({
                    ...product,
                    categoryName: categoryName // Ensure each product has the categoryName
                })));
                setLoading(false); // Stop loading once data is fetched
            })
            .catch(error => {
                setIsError(true);
                setLoading(false); // Stop loading in case of error
                console.error('Error fetching products by category:', error);
            });
    };

    // For Pagination
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    // Function to handle next page
    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredProducts.length / productsPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    // Function to handle previous page
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    // Function to handle search submit
    const handleSearchSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        // Trigger search with current search term
        // The useEffect hook for searchTerm will update filteredProducts state
    };

    // Render ErrorPage if isError is true
    if (isError) {
        return <ErrorPage />;
    }

    // Render loading indicator if products are still loading
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="flex justify-center items-center">
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="font-arapey flex flex-wrap bg-gray-150 mt-20 overflow-hidden">
                <div className="w-full md:w-3/12 p-3 md:mt-20">
                    <aside className="w-full h-cover transition-transform overflow-hidden" aria-label="Sidebar">
                        <div className="h-full overflow-y-auto dark:bg-gray-800">
                            <ul className="space-y-2 font-medium">
                                <li className="pt-2 space-y-2 font-medium relative flex justify-center md:justify-start">
                                    <a className="flex items-center p-2 text-[#777777] rounded-lg dark:text-white group">
                                        <span className="flex-1 whitespace-nowrap text-xl text-center md:text-left">Hoarding Type</span>
                                    </a>
                                    <div className="border-b ms-3 border-gray-400 dark:border-gray-700 absolute inset-x-1/5 bottom-0 w-1/3"></div>
                                </li>
                                <li className="flex justify-center md:justify-start">
                                    <Link
                                        to="/all-items"
                                        className="flex items-center p-2 rounded-lg hover:bg-transparent group"
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent default behavior
                                            window.location.href = '/all-items'; // Trigger page reload
                                        }}
                                    >
                                        <span className="flex-1 ms-3 whitespace-nowrap text-center md:text-left hover:text-blue-500 text-red-500">
                                            All Ads
                                        </span>
                                    </Link>
                                </li>
                                {categories.map(category => (
                                    <li key={category.categoryId} className="flex justify-center md:justify-start">
                                        <Link
                                            onClick={() => handleCategorySelect(category.categoryId)}
                                            className={`flex items-center p-2 rounded-lg hover:bg-gray-100 hover:underline dark:hover:bg-gray-700 group ${selectedCategory === category.categoryId ? 'text-blue-500' : 'text-red-950'} ${selectedCategory === category.categoryId ? 'active' : ''} justify-between`}
                                        >
                                            <span className="flex-1 ms-3 whitespace-nowrap text-center md:text-left">{category.name}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </aside>
                </div>

                <div className="w-full md:w-9/12 flex flex-wrap overflow-hidden">
                    <form className="w-full mb-4 flex flex-col mt-4" onSubmit={handleSearchSubmit}>
                        <div className="relative w-full max-w-lg mx-auto mb-4 p-4">
                            <input
                                type="search"
                                id="default-search"
                                className="bg-gray-50 block w-full p-4 pl-10 text-sm text-gray-900 border rounded-lg dark:bg-white dark:text-gray-600 focus:outline-none"
                                placeholder="Type to Search"
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setSelectedCategory(''); // Reset selected category when search term changes
                                    setCurrentPage(1); // Reset to first page
                                }}
                                required
                            />
                            <div className="absolute inset-y-0 left-0 flex items-center pl-6 pointer-events-none">
                                <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <select
                                className="mr-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={handleSortChange}
                                value={sortOption}
                            >
                                <option value="">Sort by</option>
                                <option value="price">Price: low to high</option>
                                <option value="size">Size</option>
                                <option value="name">Name</option>
                                <option value="category">Category</option>
                            </select>
                        </div>
                    </form>

                    {!loading && filteredProducts.length === 0 && (
                        <div className='ml-8 md:ml-72 lg:ml-72 xl:ml-72'>
                            <section className="bg-white dark:bg-gray-900">
                                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                                    <div className="flex items-center justify-center mb-4">
                                        <HiOutlineEmojiSad className="text-4xl lg:text-6xl text-primary-600 dark:text-primary-500" />
                                        <h1 className="ml-2 text-4xl tracking-tight font-bold lg:text-6xl text-primary-600 dark:text-primary-500">Oops!</h1>
                                    </div>
                                    <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                                        <h4 className="mb-4 text-2xl tracking-tight font-bold text-gray-900 dark:text-white">No Ad with this keyword.</h4>
                                        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Try something else...</p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    )}

                    {filteredProducts.slice(0, visibleCount).map(product => (
                        <Link to={`/all-items/singleItem/${product.productId}`} key={product.productId} className="p-4 md:w-1/3 w-full">
                            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:shadow-2xl">
                                {product.imagePath.length > 0 && (
                                    <img
                                        src={`${baseUrl}${product.imagePath[0]}`} // Only the first image
                                        alt={`Product 1`} // Adjust the alt text if needed
                                        className="lg:h-48 md:h-36 w-full object-cover object-center transform scale-100 transition duration-300 ease-in-out hover:scale-110"
                                    />
                                )}
                                <div className="p-6">
                                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">Rs {product.price}-{product.priceOptions}</h2>
                                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">{product.name}</h1>
                                    <p className="leading-relaxed mb-3">{product.description}</p>
                                    <p className="leading-relaxed mb-3 font-thin">Size: <span className='text-gray-400'>{product.size}</span></p>
                                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">{product.categoryName.toUpperCase()}</h2>
                                    <div className="flex items-center flex-wrap">
                                        {/* Additional content if needed */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

            {/* Pagination */}
            <div className="flex flex-col justify-between p-6 mb-3 items-center">
                {filteredProducts.length > visibleCount && (
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                        onClick={handleLoadMore}
                    >
                        Load More
                    </button>
                )}
            </div>
        </>
    );

};

export default Items;
