import React from "react";

const AdminFooter = () => (
    <footer className="p-4 bg-custom-gradient shadow md:px-6 md:py-8 dark:bg-gray-900 mt-auto font-arapey">
        <div className="flex items-center justify-between">
            {/* <a href="#" className="flex items-center mb-4 sm:mb-0">
                <img src={logo} className="h-11" alt="Pak Hoarding Logo" />
                <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">Pak Hoarding</span>
            </a> */}
            <ul className="flex flex-wrap items-center mb-6 text-sm text-white sm:mb-0">
                <li>
                    <a href="/" className="mr-4 hover:underline md:mr-6">Home</a>
                </li>
                <li>
                    <a href="/services" className="mr-4 hover:underline md:mr-6">Services</a>
                </li>
                <li>
                    <a href="/contact" className="mr-4 hover:underline md:mr-6">Contact Us</a>
                </li>
            </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700" />
        <span className="block text-sm text-white sm:text-center">© 2023 Pak Hoarding. All Rights Reserved.</span>
    </footer>
);

export default AdminFooter;
