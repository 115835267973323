import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import categicon1 from '../../images/pak-hoarding-banner003.png';
import categicon2 from '../../images/pak-hoarding-banner003.png';
import categicon3 from '../../images/pak-hoarding-banner003.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
    offset: 120,
    duration: 1000,
});

const BannerAd = () => {
    const settings = {

        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        cssEase: "ease-in-out",
        prevArrow: <></>, // Pass empty fragment to remove previous arrow
        nextArrow: <></>, // Pass empty fragment to remove next arrow
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1, // Adjust for tablets
                    slidesToScroll: 1,
                    prevArrow: <></>, // Pass empty fragment to remove previous arrow
                    nextArrow: <></>, // Pass empty fragment to remove next arrow
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1, // Adjust for mobile devices
                    slidesToScroll: 1,
                    prevArrow: <></>, // Pass empty fragment to remove previous arrow
                    nextArrow: <></>, // Pass empty fragment to remove next arrow
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1, // Adjust for smaller mobile devices
                    slidesToScroll: 1,
                    prevArrow: <></>, // Pass empty fragment to remove previous arrow
                    nextArrow: <></>, // Pass empty fragment to remove next arrow
                },
            },
        ],
    };

    return (
        <>
            <Slider {...settings} data-aos="zoom-in" className='container mx-auto max-w-screen-xl md:mt-4 scrollbar-thin scrollbar-thumb-rose-500 scrollbar-track-slate-700'>
                <div className="md:container md:mx-auto md:max-w-screen-xl md:px-12 md:py-12">
                    <img className="w-full h-auto" src={categicon1} alt="A beautiful landscape" />
                </div>

                <div className="md:container md:mx-auto md:max-w-screen-xl md:px-12 md:py-12">
                    <img className="w-full h-auto" src={categicon2} alt="A beautiful landscape" />
                </div>

                <div className="md:container md:mx-auto md:max-w-screen-xl md:px-12 md:py-12">
                    <img className="w-full h-auto" src={categicon3} alt="A beautiful landscape" />
                </div>
            </Slider>
        </>
    )
}

export default BannerAd;
