import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Footer from '../../User/Footer';
import logo from '../../../images/logo.png';
import { MdDashboard, MdOutlineAddLocationAlt, MdOutlineFeaturedPlayList, MdOutlineWorkspacePremium } from "react-icons/md";
import { IoMdPricetags } from "react-icons/io";
import { FaRegUserCircle, FaRegUser } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";
import { MdGppGood } from "react-icons/md";
import { IoMdArchive } from "react-icons/io";
import Cookies from 'js-cookie';

const UserSettings = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);
    const [user, setUser] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [totalActiveAds, setTotalActiveAds] = useState(0);
    const [totalNonActiveAds, setTotalNonActiveAds] = useState(0);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const updateUserFromLocalStorage = () => {
            const email = localStorage.getItem('email');
            const userName = localStorage.getItem('userName');
            const role = localStorage.getItem('role');

            if (email && role && userName) {
                setUser({ email, role, userName });
                setEmail(email);
                setUserName(userName);
            } else {
                setUser(null);
            }
        };

        updateUserFromLocalStorage();
        window.addEventListener('storage', updateUserFromLocalStorage);

        return () => {
            window.removeEventListener('storage', updateUserFromLocalStorage);
        };
    }, []);

    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        const storedUserName = localStorage.getItem('userName');

        if (storedEmail) {
            setEmail(storedEmail);
        }

        if (storedUserName) {
            setUserName(storedUserName);
        }
    }, []);

    // SideBar Toggle
    const toggleSidebar = () => {
        setIsSidebarOpen(prev => !prev); // Toggle the state
    };

    // SideBar Click Outside
    const handleClickOutsideSidebar = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    // SideBar Effects
    useEffect(() => {
        const sidebar = sidebarRef.current;
        if (isSidebarOpen) {
            sidebar.classList.remove('-translate-x-full');
        } else {
            sidebar.classList.add('-translate-x-full');
        }

        document.addEventListener('mousedown', handleClickOutsideSidebar);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSidebar);
        };
    }, [isSidebarOpen]);

    const handleLogout = () => {
        Cookies.remove('token');
        localStorage.removeItem('email');
        localStorage.removeItem('userName');
        localStorage.removeItem('role');
        setUser(null);
        navigate('/login');
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutsideDropdown = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDropdown);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = Cookies.get('token');
        if (!token) {
            handleLogout();
            return;
        }

        const payload = { email, userName, currentPassword, newPassword, phone };

        try {
            const response = await fetch('https://techcogads.com/api/Auth/updateprofile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const errorMessage = await response.text(); // Get error message from response
                throw new Error(errorMessage); // Throw an error with the message
            }

            setError(''); // Clear any previous error
            setMessage('Profile updated successfully');
            setTimeout(() => setMessage(''), 3000); // Clear the error after 3 seconds
            handleLogout();

        } catch (error) {
            setMessage(''); // Clear message if there was any
            setError('Failed to Update Profile. Please try again after a while');
            setTimeout(() => setError(''), 3000); // Clear the error after 3 seconds

        }
    };

    const getToken = () => {
        return Cookies.get('token');
    };

    // API for Active Ads
    useEffect(() => {
        const fetchActiveAdsCount = async () => {
            const token = getToken();
            try {
                const response = await fetch('https://techcogads.com/api/Products/CountActiveProducts', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setTotalActiveAds(data.totalActiveCount);
                } else {
                    console.error('Failed to fetch active ads count');
                }
            } catch (error) {
                console.error('Error fetching active ads count:', error);
            }
        };

        fetchActiveAdsCount();
    }, []);

    // API for NonActive Ads
    useEffect(() => {
        const fetchNonActiveAdsCount = async () => {
            const token = getToken();
            try {
                const response = await fetch('https://techcogads.com/api/Products/CountDeletedProducts', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setTotalNonActiveAds(data.totalInActiveCount);
                } else {
                    console.error('Failed to fetch non-active ads count');
                }
            } catch (error) {
                console.error('Error fetching non-active ads count:', error);
            }
        };

        fetchNonActiveAdsCount();
    }, []);

    return (
        <>
            <div className="flex h-screen overflow-hidden font-arapey">
                {/* Sidebar */}
                <aside ref={sidebarRef} id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-16 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
                    <div className="h-full px-3 pb-4 overflow-y-auto bg-custom-gradient dark:bg-gray-800">
                        <ul className="space-y-2 font-medium mt-4">
                            <li>
                                <NavLink
                                    to="/user/panel"
                                    className={({ isActive }) =>
                                        isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                    }
                                >
                                    <MdDashboard className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                    <span className="ms-3">Dashboard</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/user/post-listing"
                                    className={({ isActive }) =>
                                        isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                    }
                                >
                                    <MdOutlineAddLocationAlt className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                    <span className="flex-1 ms-3 whitespace-nowrap">Post listing</span>
                                </NavLink>
                            </li>
                            <li>
                                <li>
                                    <NavLink
                                        to="/user/archive-ads"
                                        className={({ isActive }) =>
                                            isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                        }
                                    >
                                        <IoMdArchive className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                        <span className="flex-1 ms-3 whitespace-nowrap">Archive Ads</span>
                                    </NavLink>
                                </li>
                                <NavLink
                                    to="/user/pricing"
                                    className={({ isActive }) =>
                                        isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                    }
                                >
                                    <IoMdPricetags className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                    <span className="flex-1 ms-3 whitespace-nowrap">Pricing</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/user/featured-ads"
                                    className={({ isActive }) =>
                                        isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                    }
                                >
                                    <MdOutlineFeaturedPlayList className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                    <span className="flex-1 ms-3 whitespace-nowrap">Featured Ads</span>
                                </NavLink>
                            </li>
                            {/* <li>
                            <NavLink
                                to="/admin/events"
                                className={({ isActive }) =>
                                    isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                }
                            >
                                <svg className="flex-shrink-0 w-5 h-5 text-current transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 16">
                                    <path d="m16 0-4.8 4H0v8h2v4l4.8-4H18V0h-2Zm0 10H6l-2.8 2.4v-2.4H2V6h10.8l2.8-2.4v6.4Z" />
                                </svg>
                                <span className="flex-1 ms-3 whitespace-nowrap">Events</span>
                            </NavLink>
                        </li> */}
                            <li>
                                <NavLink
                                    to="/user/user-settings"
                                    className={({ isActive }) =>
                                        isActive ? 'flex items-center p-2 text-black bg-white rounded-lg dark:text-white dark:bg-gray-700' : 'flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-white hover:text-black dark:hover:bg-gray-700'
                                    }
                                >
                                    <FaRegUser className="w-5 h-5 text-current transition duration-75 group-hover:text-black dark:text-gray-400 dark:group-hover:text-white" />
                                    <span className="flex-1 ms-3 whitespace-nowrap">User Settings</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </aside>
                {/* Right Section (Main Content) */}
                <main className="flex-1 overflow-y-auto bg-gray-100 dark:bg-gray-900">
                    {/* Navbar */}
                    <nav className="fixed top-0 z-50 w-full bg-custom-gradient border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                        <div className="px-3 py-3 lg:px-5 lg:pl-3">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center justify-start rtl:justify-end">
                                    <button onClick={toggleSidebar} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                                        <span className="sr-only">Open sidebar</span>
                                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                                        </svg>
                                    </button>
                                    <a href="/" className="flex ms-8 md:me-24">
                                        <img src={logo} className="h-11" alt="Pak Hoarding Logo" />
                                    </a>
                                </div>
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        className="flex text-sm bg-custom-gradient rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                        onClick={toggleDropdown}
                                    >
                                        <span className="sr-only">Open user menu</span>
                                        <FaRegUserCircle className="w-8 h-8 text-gray-400" />
                                    </button>
                                    {isDropdownOpen && (
                                        <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-20 dark:bg-gray-800">
                                            {/* <NavLink
                                            to="/profile"
                                            className={({ isActive }) =>
                                                isActive ? 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-600' : 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
                                            }
                                        >
                                            Your Profile
                                        </NavLink> */}
                                            <button
                                                onClick={handleLogout}
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600"
                                            >
                                                Sign out
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </nav>


                    {/* Main Content */}
                    {/* First Section */}
                    <div className="xl:ml-64 lg:ml-64 md:ml-64 p-5 mt-10 bg-white">
                        <div className="p-3 shadow-lg rounded-lg">
                            <div className="flex flex-wrap justify-center py-8 gap-8">
                                <div
                                    className="transform rounded-xl h-32 w-64 sm:h-36 sm:w-72 shadow-xl transition duration-300 hover:scale-105
    bg-gradient-to-r from-sky-500 to-emerald-500 to-90% ..."
                                >
                                    <div className="flex h-full justify-center items-center gap-4">
                                        <MdGppGood className='text-xl text-white' />
                                        <span className="font-bold text-white text-md">Active Ads</span>
                                        <span className='font-bold text-white text-md'>{totalActiveAds}</span>
                                    </div>
                                </div>

                                <div
                                    className="transform rounded-xl h-32 w-64 sm:h-36 sm:w-72 shadow-xl transition duration-300 hover:scale-105
    bg-gradient-to-r from-indigo-500 via-20% to-pink-500 ..."
                                >
                                    <div className="flex h-full justify-center items-center gap-4">
                                        <IoMdEyeOff className='text-xl text-white' />
                                        <span className="font-bold text-white text-md">Non-Actve Ads</span>
                                        <span className='font-bold text-white text-md'>{totalNonActiveAds}</span>
                                    </div>
                                </div>

                                <div
                                    className="transform rounded-xl h-32 w-64 sm:h-36 sm:w-72 shadow-xl transition duration-300 hover:scale-105
    bg-gradient-to-r to-cyan-500 from-indigo-500 ..."
                                >
                                    <div className="flex h-full justify-center items-center gap-4">
                                        <MdOutlineWorkspacePremium className='text-xl text-white' />
                                        <span className="font-bold text-white text-md">Featured Ads</span>
                                        <span className="font-bold text-white text-md">0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Alerts  */}
                    {message && (
                        <div className="ml-80 flex items-center justify-center mt-6">
                            <div className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <span className="sr-only">Info</span>
                                <div>
                                    <span className="font-medium">{message}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {error && (
                        <div className="ml-80 flex items-center justify-center mt-6">
                            <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <span className="sr-only">Info</span>
                                <div>
                                    <span className="font-medium">{error}</span>
                                </div>
                            </div>
                        </div>
                    )}


                    {/* USer Settings Form */}
                    <div className="xl:ml-64 p-5 mt-5 bg-white shadow-lg rounded-lg">
                        <h2 className="text-2xl font-bold mb-8 text-center text-gray-800">User Settings</h2>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="relative z-0 w-full mb-5 group">
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent peer"
                                    placeholder="Email"
                                    required
                                />
                            </div>
                            <div className="grid md:grid-cols-2 md:gap-6">
                                <div className="relative z-0 w-full mb-5 group">
                                    <input
                                        type="text"
                                        id="userName"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent peer"
                                        placeholder="User Name"
                                        required
                                    />
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input
                                        type="text"
                                        id="phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent peer"
                                        placeholder="Phone No"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="grid md:grid-cols-2 md:gap-6">
                                <div className="relative z-0 w-full mb-5 group">
                                    <input
                                        type="password"
                                        id="currentPassword"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent peer"
                                        placeholder="Current Password"
                                        required
                                    />
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input
                                        type="password"
                                        id="newPassword"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent peer"
                                        placeholder="New Password"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="text-right">
                                <button type="submit" className="inline-flex items-center px-6 py-3 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800">
                                    Update Settings
                                    <svg className="w-4 h-4 ml-2 -mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>


                    {/* Advertiser Footer */}
                    <div className="bg-custom-gradient dark:bg-gray-800 text-white p-5 text-center mt-auto xl:ml-56 lg:ml-56 md:ml-56">
                        <Footer />
                    </div>
                </main>
            </div>
        </>

    );
};
export default UserSettings;
